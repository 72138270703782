<template>
    <v-container>
        <v-alert v-if="!location_id" class="pa-4 ma-4" type="error" text>You must select a customer first!</v-alert>
        <v-form v-model="invoiceForm" class="pa-4">
          <v-row>
            <v-col cols="4" offset="8"><v-text-field v-model="invoice.no" label="Invoice Number" outlined dense hide-details="auto" :rules="[fieldRequired]"></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col cols="4" offset="8"><DatePicker v-model="invoice.date" label="Invoice Date" outlined dense hide-details="auto" :rules="[fieldRequired]" ref="invDate"></DatePicker></v-col>
          </v-row>
          <v-row>
            <v-col cols="4" offset="8"><v-text-field v-model="invoice.dueIn" label="Due In" type="number" outlined dense hide-details="auto" :rules="[fieldRequired]"></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col cols="12"><v-btn small color="primary" @click="invoice.items.push({id: null, name: null, description: null, unit_cost: 0, qty: 0})"><v-icon>mdi-plus</v-icon> Add Item</v-btn></v-col>
          </v-row>
          <v-row v-for="(item, index) in invoice.items" :key="index">
            <v-col cols="6">
              <v-text-field v-model="item.name" label="Item Description" outlined dense hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="item.unit_cost" label="Unit Cost" outlined dense hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="item.qty" label="Qty" outlined dense hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field :value="parseFloat(item.qty*item.unit_cost).toFixed(2)" label="Net Total" readonly outlined dense hide-details="auto"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="invoice.notes" label="Notes" rows="3" outlined dense hide-details="auto"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="invoice.terms" label="Payment Terms" rows="4" outlined dense hide-details="auto" :rules="[fieldRequired]"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" offset="6" class="text-right">
                <v-btn small @click="previewInvoice()" :disabled="!location_id || !invoiceForm">Preview</v-btn>
                <v-btn small @click="saveInvoice()" color="primary" class="px-6 ml-2" :disabled="!report_id || !invoiceForm">Save</v-btn></v-col>
          </v-row>
        </v-form>
    </v-container>
</template>

<script>
  import DatePicker from "../components/DatePickerInput.vue";

export default {
    props: {
        location_id: {type: [String, Number], required: true},
        report_id: {type: Number, required: true},
    },
    components: {
        DatePicker,
    },
    data() {
        return {
            invoiceForm: false,
            invoice: {
                no: null,
                date: null,
                dueIn: 7,
                items: [
                    {id: null, name: null, unit_cost: 0, qty: 0},
                    {id: null, name: null, unit_cost: 0, qty: 0},
                ],
                notes: null,
                terms: null,
            },
            fieldRequired: function (val) {
                return !!val || "This is a required field!";
            },
        }
    },
    methods: {
        base64Encode(str) {
            const utf8Bytes = new TextEncoder().encode(str);
            const base64 = btoa(String.fromCharCode(...utf8Bytes));
            return base64;
        },
        previewInvoice() {
            let object = {};
            object.location_id = this.location_id;
            object.client_id = this.$store.state.user.payload.id;
            object.invoice = this.invoice;
            object.preview = true;

            window.open("https://patconnect.com/api/invoice.php?data="+this.base64Encode(JSON.stringify(object)), '_blank');
        },
        saveInvoice() {
            let object = {};
            object.report_id = this.report_id;
            object.location_id = this.location_id;
            object.client_id = this.$store.state.user.payload.id;
            object.invoice = this.invoice;
            object.preview = false;

            window.open("https://patconnect.com/api/invoice.php?data="+this.base64Encode(JSON.stringify(object)), '_blank');
            this.$emit('invoice-saved');
        }
    },
    mounted() {
        this.$refs['invDate'].today();
        this.invoice.terms = this.$store.state.user.preferences.payment_terms;
    }
}
</script>