<template>
    <v-container fluid>
        <v-card-text>
            <crud
                label="Saved Reports"
                model="reports"
                ref="reports"
                :headers="headers"
                :conditions="conditions"
                :initialValues="{client_id:$store.state.user.payload.id}"
                :canAdd="false"
                :canEdit="false"
                :canSearch="true"
                :canFilter="true"
                :key="rerender"
                v-model="selected"
                show-select
                fixed-header
            >
                <template v-slot:[`item.locations.parent_id`]="obj">
                    <span v-if="obj.item['locations.parent_id'] > 0">{{getCustomer(obj.item['locations.parent_id'])}}</span>
                    <span v-else>{{obj.item['locations.name']}}</span>
                </template>
                <template v-slot:[`item.locations.name`]="obj">
                    <span v-if="obj.item['locations.parent_id'] > 0">{{obj.item['locations.name']}}</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.input_file`]="obj">
                    <v-btn small icon v-for="(v, i) in JSON.parse(obj.item.input_file)" :key="i" :href="v?.url" target="_blank" :title="v?.name"><v-icon color="primary">mdi-file</v-icon></v-btn>
                </template>
                <template v-slot:[`item.output_file`]="obj">
                    <v-btn small icon v-for="(v, i) in JSON.parse(obj.item.output_file)" :key="i" :href="v?.url" target="_blank" :title="v?.name"><v-icon color="red">mdi-file-pdf-box</v-icon></v-btn>
                </template>
                <template v-slot:[`item.invoice`]="obj">
                    <v-btn small icon v-for="(v, i) in JSON.parse(obj.item.invoice)" :key="i" :href="v?.url" target="_blank" :title="v?.name"><v-icon color="red">mdi-invoice-list-outline</v-icon></v-btn>
                </template>
                <template v-slot:[`item.edit`]="obj">
                    <v-btn small icon v-if="obj.item.headers" @click="$emit('editreport', obj.item)"><v-icon>mdi-pencil</v-icon></v-btn>
                </template>
                <template v-slot:[`item.reminders`]="obj">
                    <v-btn small icon @click="toggleItem(obj.item.id, 'reminders', 0)" v-if="obj.item.reminders=='1'"><v-icon small color="success">mdi-check-circle</v-icon></v-btn>
                    <v-btn small icon @click="toggleItem(obj.item.id, 'reminders', 1)" v-else><v-icon small>mdi-close-circle</v-icon></v-btn>
                </template>
                <template v-slot:[`item.next_test_booked`]="obj">
                    <v-btn small icon @click="toggleItem(obj.item.id, 'next_test_booked', 0)" v-if="obj.item.next_test_booked=='1'"><v-icon small color="success">mdi-check-circle</v-icon></v-btn>
                    <v-btn small icon @click="toggleItem(obj.item.id, 'next_test_booked', 1)" v-else><v-icon small>mdi-close-circle</v-icon></v-btn>
                </template>
                <template v-slot:[`item.created`]="obj">
                    {{new Date(obj.item.created).toLocaleDateString()}}
                </template>
                <template v-slot:[`item.valid_to`]="obj">
                    {{new Date(obj.item.valid_to).toLocaleDateString()}}
                </template>
                <template v-slot:[`item.sendmail`]="obj">
                    <v-btn small icon :href="'mailto:'+obj.item['locations.email']+'?subject=PAT Certificate&body=View Certificate Online: ' + JSON.parse(obj.item.output_file)?.[0]?.['url'] " :disabled="!obj.item['locations.email']"><v-icon small>mdi-email</v-icon></v-btn>
                </template>
                <!-- FITLERS -->
                <template v-slot:filters>
                    <v-row density="compact">
                        <v-col cols="12">
                            <h5>Filters:</h5>
                        </v-col>
                        <v-row density="compact">
                            <v-col cols="6" sm="6" md="6" lg="3">
                                <v-menu v-model="filters[0]['menu']" offset-y min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-bind="attrs" underlined dense v-model="filters[0].value" :label="filters[0]['text']" readonly prepend-icon="mdi-calendar" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters[0].value" @input="filters[0]['menu'] = false"></v-date-picker>
                                </v-menu>
                                
                                <v-menu v-model="filters[1]['menu']" offset-y min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-bind="attrs" underlined dense v-model="filters[1].value" :label="filters[1]['text']" readonly prepend-icon="mdi-calendar" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters[1].value" @input="filters[1]['menu'] = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" lg="3">
                                <v-menu v-model="filters[2]['menu']" offset-y min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-bind="attrs" underlined dense v-model="filters[2].value" :label="filters[2]['text']" readonly prepend-icon="mdi-calendar" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters[2].value" @input="filters[2]['menu'] = false"></v-date-picker>
                                </v-menu>
                                
                                <v-menu v-model="filters[3]['menu']" offset-y min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-bind="attrs" underlined dense v-model="filters[3].value" :label="filters[3]['text']" readonly prepend-icon="mdi-calendar" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters[3].value" @input="filters[3]['menu'] = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" lg="3">
                                <v-autocomplete v-model="filters[4].value" :label="filters[4].text" :items="customers" item-value="id" item-text="name" dense></v-autocomplete>

                                <v-autocomplete v-model="filters[5].value" :label="filters[5].text" :items="filteredDepartments" item-value="id" item-text="name" dense></v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" lg="3">
                                <v-checkbox v-model="filters[6].value" :label="filters[6].text" :true-value="1" :false-value="0" hide-details="auto" dense></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-row>
                    <v-row density="compact" class="text-right">
                        <v-col cols="12">
                            <v-btn small class="rounded-0" @click="$refs.reports.setConditions(resetConditions())">Reset</v-btn>
                            <v-btn small color="primary" class="ml-2 rounded-0" @click="applyFilters()">Apply</v-btn>
                        </v-col>
                    </v-row>
                </template>
            </crud>
        </v-card-text>
        <v-card-actions>
            <v-btn :disabled="selected.length < 2" class="mx-auto" @click="$emit('mergereports', selected)">Merge Selected Reports</v-btn>
        </v-card-actions>
    </v-container>
</template>

<script>
import crud from "../components/CRUD.vue"
export default {
    components: {
        crud,
    },
    props: {
        location_id: {required: false},
    },
    data() {
        return {
            headers: [
                {text:"ID", value:"id", isId:true, visible: false, editable: false},
                {text:"Client ID", value:"client_id", visible: false, editable: false},
                {text:"Customer", value:"locations.parent_id", modelId: "location_id", visible:true, editable: false, slot:true},
                {text:"Department", value:"locations.name", modelId: "location_id", modelFields: {"text":"name", "value":"id"}, searchable: true, slot:true},
                {text:"Address 1", value:"locations.address",  modelId: "location_id", searchable: true},
                {text:"City", value:"locations.city",  modelId: "location_id", searchable: true},
                {text:"Customer Email", value:"locations.email", modelId: "location_id", visible:false,},
                {text:"Report Date", value:"created", slot: true},
                {text:"Valid To", value:"valid_to", slot: true},
                {text:"Notes", value:"notes"},
                {text:"PAT File", value:"input_file", mandatory: true, slot:true},
                {text:"PDF Report", value:"output_file", mandatory: true, slot:true},
                {text:"Invoice", value:"invoice", mandatory: false, slot:true},
                {text:"Headers", value:"headers", visible: false},
                {text:"Location ID", value:"location_id", visible: false},
                {text:"Next Test Booked", value:"next_test_booked", type: "switch", slot: true},
                {text:"Reminders", value:"reminders", type: "switch", slot: true},
                {text:"Email Report", value:"sendmail", query:false, slot: true, sortable: false},
                {text:"Edit", value:"edit", query: false, slot:true, sortable: false},
            ],
            filters: [
                {text: "Report Date From", field: "created", value: null, op: "meq", menu: false},
                {text: "Report Date To", field: "created", value: null, op: "leq", menu: false},
                {text: "Valid Date From", field: "valid_to", value: null, op: "meq", menu: false},
                {text: "Valid Date To", field: "valid_to", value: null, op: "leq", menu: false},
                {text: "Customer", field: "parent_location_id", value: null, op: "eq"},
                {text: "Department", field: "location_id", value: null, op: "eq"},
                {text: "Next Test Booked", field: "next_test_booked", value: 0, op: "eq"},
            ],
            conditions: [],
            customers: [],
            departments: [],
            filteredDepartments: [],
            selected: [],
            rerender: 0,
        }
    },
    methods: {
        toggleItem(id, name, newVal) {
            this.axios.post(this.ApiEndpoint, {
                operation: 'update',
                table: 'reports',
                data: [{'id': id, [name]: newVal}]
            }).then(() => {
                this.rerender++;
            })
        },
        getCustomer(parent_id) {
            return this.customers.filter(item => item.id == parent_id)?.[0]?.name;
        },
        filterConditions() {
            let cond = this.resetConditions(false)

            this.filters.forEach(function (item) {
                if(item.value) {
                    if(item.op.indexOf("like") == -1)
                        cond.push({[item.field]: { [item.op]: item.value }})
                    else
                        cond.push({[item.field]: { [item.op]: "%" + item.value + "%" }})
                }
            })

            return cond
        },
        resetConditions(freset = true) {
            let cond = this.$refs.reports.totalConditions?.filter((obj) => {
                let notInitial = true;
                this.filters.forEach((item) => {
                    if(freset) item.value = null
                    if(Object.keys(obj)[0] == item.field) {
                        notInitial = false
                    }
                })
                return notInitial
            })

            return cond
        },
        applyFilters() {
            let cond = this.filterConditions();
            this.$refs.reports.setConditions(cond)
        }
    },
    beforeMount() {
        this.conditions = [{'client_id': {'eq': this.$store.state.user.payload.id}}, {'deleted': {'eq':'0'}}];

        if(this.location_id) {
            this.conditions.push({'location_id': {'eq': this.location_id}});
        }
    },
    mounted() {
        let app = this;

        this.axios.post(this.ApiEndpoint, {
            operation: "select",
            table: "locations",
            fields: ["id", "name"],
            where: [{'deleted': {'eq': '0'}}, {'parent_id': {'eq': '0'}}, {'client_id': {'eq': app.$store.state.user.payload.id}}],
        }).then(res => {
            if(res.data?.data?.length) {
                app.customers = res.data.data;
            }
        });

        this.axios.post(this.ApiEndpoint, {
            operation: "select",
            table: "locations",
            fields: ["id", "parent_id", "name"],
            where: [{'deleted': {'eq': '0'}}, {'parent_id': {'more': '0'}}, {'client_id': {'eq': app.$store.state.user.payload.id}}],
            order: {"parent_id": "ASC"}
        }).then(res => {
            if(res.data?.data?.length) {
                app.departments = res.data.data;
                app.filteredDepartments = res.data.data;
            }
        })
    },
    watch: {
        filters: {
            handler(v) {
                if(v[4].value) {
                    this.filteredDepartments = this.departments.filter((item) => {
                        return item.parent_id == v[4].value;
                    })
                }
                else this.filteredDepartments = this.departments;
            },
            deep: true
        }
    }
}
</script>